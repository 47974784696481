<template>
  <div class="height">
      <div class="ask-bac">
        <vue-header title="问诊" :fixed="tyle==1?true:false"/>
        <div v-if="tyle==1">
            <div class="ask-box ask-bottom flex flex-x">
              <div>
                <div class="base-box">1. 基础信息</div>
                <div class="ask-quiz">
                    <div class="quiz flex" :class="{ quiz_select : item.id>0 }" v-for="( item, index ) in problem.data" v-bind:key="index">
                      <div class="quiz-name">{{item.id>0?`${index-flow_base+2}. `:''}}{{item.alias?item.alias:item.title}}：
                        <div class="ask-width" v-if="item.is_multiple==1">
                          <div class="ask-choice">多选</div>
                        </div>
                      </div>
                      <div v-if="item.no_select">
                          <div v-if="item.id==-2">
                            <div class="quiz-ridg flex">
                              <input class="min-n" type="tel" v-model="date.data[index].content[0]" />
                              <div class="bridging">年</div>
                              <input class="mins" type="tel" v-model="date.data[index].content[1]"/>
                              <div class="bridging">月</div>
                              <input class="mins" type="tel" v-model="date.data[index].content[2]"/>
                              <div class="bridging">日</div>
                            </div>
                            <!-- <div class="quiz-ridgs flex">
                              <input class="mins" type="tel" v-if="date.data[index].content[3]=='00'" value=""/>
                              <input class="mins" type="tel" v-else v-model="date.data[index].content[3]"/>
                              <div class="bridging">时</div>
                              <input class="mins" type="tel" v-if="date.data[index].content[4]=='00'" value=""/>
                              <input class="mins" type="tel" v-else v-model="date.data[index].content[4]"/>
                              <div class="bridging">分</div>
                            </div> -->
                        </div>
                        <div class="flex" v-else>
                          <input :class="{min:!item.long}" type="text" v-model="date.data[index].content[0]"/>
                          <div class="quiz-mi" v-if="item.unit">{{item.unit}}</div>
                        </div>
                      </div>
                      <div class="ask-option quiz-option flex" v-else>
                          <div class="k-option" :class="{ active: cont.active }" v-for="( cont, index1 ) in item.content" v-bind:key="index1" @click="()=>problemChange({
                                  key:index,
                                  index:index1,
                                  value:true
                              })">{{item.id>0?`${['A','B','C','D','E','F','G','H','I','J','K'][index1]}. `:''}}{{screenFun(cont.label)}}</div>
                      </div>
                    </div>
                </div>
                <div class="ask-cheak flex flex-x ask-submit">
                  <div class="next" @click="()=>submit()">提交</div>
                </div>
              </div>
            </div>
        </div>
        <div v-else>
            <div class="ask-box" v-for="( item, index ) in problem.data" v-bind:key="index">
              <div v-if="index==page">
                  <div class="ask-num flex">
                      <div class="flex-1"></div>
                      <div><span>{{page+1>10?page+1:`0${page+1}`}}</span> / {{problem.data.length>9?problem.data.length:`0${problem.data.length}`}}</div>
                  </div>
                  <div class="ask-problem">{{page+1}}.{{item.title}}
                    <div class="ask-width" v-if="item.is_multiple==1">
                      <div class="ask-choice">多选</div>
                    </div>
                  </div>
                  <div v-if="item.no_select">
                      <div class="ask-year flex" v-if="item.id!==-2">
                          <div class="ask-time short" :class="{ idcard: item.long }" >{{item.content[0]}}</div>
                          <div>{{item.unit}}</div>
                      </div>
                      <div class="ask-year flex" v-else>
                        <div class="ask-time year" :class="{ active: item.date==0 }" @click="itemFun(0,index)">{{item.content[0]}}</div>
                        <div>年</div>
                        <div class="ask-time" :class="{ active: item.date==1 }" @click="itemFun(1,index)">{{item.content[1]}}</div>
                        <div>月</div>
                        <div class="ask-time" :class="{ active: item.date==2 }" @click="itemFun(2,index)">{{item.content[2]}}</div>
                        <div>日</div>
                        <!-- <div class="ask-time" :class="{ active: item.date==3 }" @click="itemFun(3,index)">{{item.content[3]=='00'?'':item.content[3]}}</div>
                        <div>:</div>
                        <div class="ask-time" :class="{ active: item.date==4 }" @click="itemFun(4,index)">{{item.content[4]=='00'?'':item.content[4]}}</div> -->
                    </div>
                      <div class="ask-clavier">
                        <van-number-keyboard :show="true"
                                             :extra-key="item.extra?item.extra:'清空'"
                                             @input="(value)=>problemChange({
                                                key:index,
                                                value:value
                                             })"
                                             @delete="()=>problemChange({
                                                key:index,
                                                value:-1
                                             })"
                        />
                      </div>
                  </div>
                  <div v-else>
                      <div class="ask-option ask-child flex" v-if="item.id==-1">
                        <div class="k-option flex-1" :class="{ active: cont.active }"  v-for="( cont, index1 ) in item.content" v-bind:key="index1" @click="()=>problemChange({
                              key:index,
                              index:index1,
                              value:true
                          })">
                          {{screenFun(cont.label)}}
                        </div>
                      </div>
                      <div v-else class="aho-option">
                          <div class="ask-option flex" v-for="( cont, index1 ) in item.content" v-bind:key="index1">
                              <div class="k-option flex-1" :class="{ active: cont.active }" @click="()=>problemChange({
                                  key:index,
                                  index:index1,
                                  value:true
                              })">{{screenFun(cont.label)}}</div>
                          </div>
                      </div>
                  </div>
                  <div class="ask-cheak flex flex-x" :class="{ cheak:page<4 }">
                      <div class="prev" v-if="page!==0" @click="()=>pageChange({
                        value:page-1
                      })">上一题</div>
                      <div class="next" v-if="page+1!==problem.data.length" @click="()=>pageChange({
                        value:page+1
                      })">下一题</div>
                      <div class="next" v-else @click="()=>submit()">提交</div>
                  </div>
              </div>
            </div>
        </div>
      </div>
      <div v-if="show">
        <vue-popup url="report" :showFun="showFun" :token="token" :callback="callbackFun" />
      </div>
      <div class="loading flex flex-x flex-y" v-if="loading">
        <div class="loading-box">
          <div class="loading-icon">
            <van-loading />
            <div class="loading-ui">提交中</div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import vueHeader from '@/components/header';
import { mapState, mapActions, mapMutations } from "vuex";
import vuePopup from "@/components/popup";

export default {
  name: 'question',
  components:{
    vueHeader,
    vuePopup
  },
  created: function () {
    this.questions();    //获取问题
  },
  destroyed: function () {
      this.closeFun();
  },
  computed: {
    date:{
        get() { 
          return this.$store.state.question.problem 
        }
    },
    ...mapState ("question", ["problem","tyle","page","flow_base","show","token","loading"]),
  },
  methods: {
    submit(){             //提交问诊
      this.submitData({_this:this});
    },
    showFun(){
      this.change({
          key:'show',
          value:false
      })
    },
    callbackFun(){
      this.$router.push({
          name:'report',
          params: {
              id:this.token
          }
      })
    },
    //乳房胀痛
    screenFun(label){
        let data = this.$store.state.question.problem.data[0];
        let sex = 2;
        if(data.id==-1 && data.content[0].active){
            sex = 1;
        }
        if(sex==1 && label=='乳房胀痛'){
            return '胸胀胸痛';
        }else{
            return label;
        }
    },
    itemFun(val,index){
      let problem = this.problem;
      problem.data[index].date = val;
      this.change({
          key:'problem',
          value:{
            renovate:!problem.renovate,
            data:problem.data
          }
      })
    },
    ...mapMutations ("question", ["change","pageChange","questions","problemChange","closeFun"]),
    ...mapActions ("question", ["axiosData","submitData"])
  }
}
</script>
<style lang="scss" scoped rel="stylesheet/scss">
  @import 'question';
</style>